@use '~@ampeersenergy/dm-style-lib/utils' as utils;

.card {
  box-sizing: border-box;
  flex: 1;
  --card-font-size: 16px;
  // width: var(--column-lane-width);

  ae-ui-card-header,
  ae-ui-card-content {
    text-transform: none;
  }

  ae-ui-card-title {
    width: 190px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
  }

  ae-ui-card-subtitle {
    width: 190px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
  }
}
.react-kanban-column > div {
  width: 230px;
}
.label {
  font-weight: var(--font-weight-regular);
  font-size: small;
}

.actions {
  --icon-size: 20px;
}

.edit {
  --icon-size: 20px;
  @include utils.position(absolute, 8px 8px auto auto);
}
