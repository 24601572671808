.column-header {
  margin-bottom: 20px;
  padding-bottom: 5px;
  border-bottom: 2px solid #e2e2e2;
  display: flex;
  align-items: baseline;
}

.title {
  padding-bottom: 0px;
  font-size: 18px;
  flex: 1;
}
.count {
  color: var(--color-grey);
  // font-size: 1.3rem;
  text-align: right;
}
