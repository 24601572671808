.react-kanban-column {
  // --column-lane-width: 250px;

  background: transparent;
  box-sizing: content-box;
  margin: 0;

  display: flex !important;
  flex-direction: column;

  height: auto !important;
  // width: var(--column-lane-width);
  flex: 1;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  // these are the headers and drop zones
  > div {
    // without the header
    &:not(:first-of-type) {
      flex: 1 0 auto;
    }

    // the card is wrapped in this container for drag-and-drop
    > div {
      margin-bottom: 20px;
    }
  }
}
