.react-kanban-board {
  height: calc(100vh - 80px);
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 20px;
  padding-right: 20px;
  overflow-x: scroll;
  overflow-y: auto !important;

  /**
  * don't hide the scrollbars on osx
  */
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 8px;
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  > div {
    display: flex !important;
    flex-direction: row;
    flex: 1;
  }
}
