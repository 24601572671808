form {
  display: flex;
  flex-direction: column;
}

.label {
  text-transform: none;
  font-weight: var(--font-weight-regular);
  font-size: small;
}

.form {
  display: grid;
  grid-gap: 0 15px;
  grid-template-columns: repeat(6, 1fr);
  padding: 15px 0 30px;
  width: 50vw;
  min-width: 1000px;

  &-field {
    grid-column-end: span 4;

    &.isperson {
      grid-column-end: span 2;
    }

    &.address-label {
      grid-column-end: span 3;
    }
    &.shippingaddress-label {
      grid-column-end: span 3;
    }

    &.city,
    &.street {
      grid-column-end: span 2;
    }

    &.street-no,
    &.zip-code {
      grid-column-end: span 1;
    }

    &.city,
    &.zip-code {
      margin-top: 10px;
    }

    &.shippingaddresscity,
    &.shippingaddresszipcode {
      margin-top: 10px;
    }

    &.shippingaddresscity,
    &.shippingaddressstreet {
      grid-column-end: span 2;
    }

    &.shippingaddressstreetnr,
    &.shippingaddresszipcode {
      grid-column-end: span 1;
    }

    &.company-name {
      grid-column-end: span 2;
    }

    &.co {
      grid-column-end: span 2;
      margin-top: 33px;
    }

    &.empty-space {
      grid-column-end: span 4;
    }

    &.leadorigin {
      grid-column-end: span 2;
      label {
        margin-bottom: 5px;
      }
    }

    &.reduction {
      grid-column-end: span 2;
    }
    &.reductionStartDate {
      grid-column-end: span 2;
      padding-bottom: 20px;
    }

    &.prefix {
      grid-column-end: span 2;
      label {
        margin-bottom: 5px;
      }
    }

    &.name {
      grid-column-end: span 2;
    }

    &.contractStartDate {
      grid-column-end: span 2;
    }
    &.contractEndDate {
      grid-column-end: span 2;
    }

    &.contractEndDate-input-field {
      margin-top: 33px;
      grid-column-end: span 2;
    }

    &.expected-consumption,
    &.phone,
    &.mail {
      grid-column-end: span 2;
    }

    &.plantid,
    &.tariffid,
    &.contractType,
    &.meterid {
      grid-column-end: span 2;
    }
    &.notes {
      grid-column-end: span 6;
    }
  }

  & .title {
    grid-column-end: span 6;
    margin-bottom: 0px;
  }

  ~ [type='button'] {
    align-self: flex-end;
  }
}

.lastRow {
  display: flex;
  justify-content: space-between;
}
